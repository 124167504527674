<template>
  <div>
    <div v-if="contractorLoading" class="skeleton-card-wrapper">
      <v-skeleton-loader type="image" class="skeleton-card"></v-skeleton-loader>
      <v-skeleton-loader type="image" class="skeleton-card"></v-skeleton-loader>
      <v-skeleton-loader type="image" class="skeleton-card"></v-skeleton-loader>
    </div>
    <div v-else class="users-list" :class="{ details: showDetails }">
      <div
        v-for="item in items"
        :key="item.id"
        class="user-item user-info"
        :class="{
          active: item.id === contractor.id,
          red: item.debt,
        }"
        @click="getContractor(item.id)"
      >
        <div class="avatar">
          <img v-if="item.avatar" :src="item.avatar" alt="" />
          <img src="@/assets/images/company.svg" alt="" />
        </div>
        <div class="text">
          <div class="name">{{ item.name }}</div>
          <div class="user-status">{{ item.service }}</div>
        </div>
      </div>
    </div>

    <div class="user-details" v-if="showDetails">
      <div class="side-modal-wrap">
        <div class="hide" @click="closeDetails">
          Скрыть <img src="@/assets/images/house-arrow.svg" alt="" />
        </div>
        <div class="executor-info">
          <div class="avatar">
            <img v-if="contractor.avatar" :src="contractor.avatar" alt="" />
            <img v-else src="@/assets/images/company.svg" alt="" />
          </div>
          <div class="text">
            <div class="name">{{ contractor.name }}</div>
            <div class="job-status">{{ contractor.service }}</div>
          </div>
        </div>
        <div class="executor-description">
          <div class="item">
            <div class="left">Телефон</div>
            <div class="right">
              {{ contractor.phone | VMask("+# (###) ###-##-##") }}
            </div>
          </div>
          <div class="item">
            <div class="left">E-mail</div>
            <div class="right">{{ contractor.email }}</div>
          </div>
          <div class="item">
            <div class="left">Юр. адрес</div>
            <div class="right">{{ contractor.address }}</div>
          </div>
          <div class="item">
            <div class="left">БИН</div>
            <div class="right">{{ contractor.bin }}</div>
          </div>
          <div class="item">
            <div class="left">Ф.И.О руководителя</div>
            <div class="right">{{ contractor.chief }}</div>
          </div>
          <div class="item">
            <div class="left">Разовая услуга</div>
            <div class="right">{{ contractor.is_onetime ? "Да" : "Нет" }}</div>
          </div>
        </div>
        <div class="doc-block" v-if="contractor.contract">
          <div class="image">
            <img src="@/assets/images/file.svg" alt="" />
          </div>
          <div class="text">
            <div class="doc-name">Договор</div>
            <div class="open">Открыть</div>
          </div>
        </div>
      </div>
      <div class="btn-row">
        <CreateContractor
          :isEditingContractor="true"
          :propTab="1"
          :userObj="contractor"
          @update="updateUser"
        />
        <!--        <div class="edit-btn" @click="$emit('edit', contractor)">Редактировать</div>-->
        <div class="delete-btn" @click="deleteContractor">Удалить</div>
      </div>
    </div>
    <div v-show="showDeleteUser">
      <DeleteUser
        :item="contractor"
        :type="type"
        :userId="contractor.id"
        @close="showDeleteUser = false"
        @closeDetails="closeDetails"
        @updateList="init"
      />
    </div>
  </div>
</template>
<script>
import { contractorApi, contractorIdApi } from "@/api/contractors";
import DeleteUser from "../../components/modals/DeleteUser";
import CreateContractor from "../../components/modals/CreateContractor";

export default {
  components: {
    CreateContractor,
    DeleteUser,
  },
  data: () => ({
    contractorLoading: true,
    items: [],
    contractor: {},
    showDetails: false,
    showDeleteUser: false,
  }),
  created() {
    this.init();
  },
  computed: {
    type() {
      return this.$route.name.split(".")[1];
    },
  },
  methods: {
    init() {
      this.getContractors();
    },
    updateUser(id) {
      this.getContractor(id);
      this.init();
    },
    toggleDeleteUser(item) {
      this.deleteItem = item || {};
      this.showDeleteUser = !this.showDeleteUser;
    },
    async getContractors() {
      await contractorApi().then((res) => {
        this.items = res.data;
        console.log(res.data);
        // this.items = res.data.map((data) => ({
        //   id: data.id,
        //   block: data.block,
        //   entrance: data.entrance,
        //   avatar: data.user_avatar?.avatar,
        //   debt: deta.debt,
        // }));
      });
      this.contractorLoading = false;
    },
    async getContractor(id) {
      await contractorIdApi(id).then((res) => {
        const contractorData = res.data;
        this.contractor = {
          id: contractorData.id,
          name: contractorData.name || "-",
          service: contractorData.service || "-",
          bin: contractorData.idn || "-",
          address: contractorData.address || "-",
          email: contractorData.email || "-",
          phone: contractorData.phone || "-",
          chief: contractorData.chief || "-",
          avatar: contractorData.user_avatar?.avatar,
          contract: contractorData.contract,
          is_onetime: contractorData.is_onetime,
        };
      });
      if (this.showDetails === true) {
        this.showDetails = false;
        this.showDetails = true;
      } else {
        this.showDetails = !this.showDetails;
        this.$emit("resizeTabsRow");
      }
      console.log("contractor", this.contractor);
    },
    closeDetails() {
      this.contractor = {};
      this.showDetails = false;
      this.$emit("resizeTabsRow");
    },
    deleteContractor() {
      this.showDeleteUser = true;
    },
  },
};
</script>
<style>
</style>